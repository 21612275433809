/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PlotForSale from "../../assets/images/land/PlotForSale.jpg";
import Helmet from "../../components/Helmet/Helmet";
import CommonSection from "../../components/CommonSection";
import { useNavigate } from "react-router-dom";

const Land = () => {
  const navigate = useNavigate();

  return (
    <Helmet title="Land">
      <CommonSection title="Land for Sale" bgImage="common__section_land" />
      <section class="blog" id="blog">
        <div class="container">
          <p class="section-subtitle">Land</p>

          <ul class="blog-list xhas-scrollbar gap-x-4 gap-y-6 grid md:grid-cols-3">
            <li>
              <div class="blog-card" onClick={() => navigate("details")}>
                <figure class="card-banner">
                  <img
                    src={PlotForSale}
                    alt="Land for sale"
                    class="xw-[500px] xh-[300px]"
                  />
                  <div class="card-badge orange">For Sale</div>
                  <div class="banner-actions">
                    <button class="banner-actions-btn">
                      <ion-icon name="location"></ion-icon>

                      <address>Gasabo-Nduba, Kigali-Rwanda</address>
                    </button>
                  </div>
                </figure>

                <div class="blog-content">
                  <div className="card-price -mt-4 pb-3">
                    <strong>$ 34,900</strong>
                  </div>
                  <div class="blog-content-top">
                    <ul class="card-meta-list">
                      <li>
                        <a class="card-meta-link">
                          <i class="ri-shape-2-line text-[#ff5a3d]"></i>

                          <span>400 sqm</span>
                        </a>
                      </li>

                      <li>
                        <a class="card-meta-link">
                          <ion-icon name="pricetags"></ion-icon>

                          <span>1/12/21/12/76690</span>
                        </a>
                      </li>
                    </ul>

                    <h3 class="h3 blog-title">
                      <a className="line-clamp-2">Land for sale</a>
                    </h3>
                  </div>

                  <div class="blog-content-bottom">
                    <div class="publish-date">
                      <ion-icon name="calendar"></ion-icon>

                      <time datetime="2022-27-04">Apr 27, 2022</time>
                    </div>

                    {/* <a class="read-more-btn">Read More</a> */}
                  </div>
                </div>
              </div>
            </li>

            <li>
              <div class="blog-card" onClick={() => navigate("details")}>
                <figure class="card-banner">
                  <img
                    src={PlotForSale}
                    alt="Land for sale"
                    class="xw-[500px] xh-[300px]"
                  />
                  <div class="card-badge orange">For Sale</div>
                  <div class="banner-actions">
                    <button class="banner-actions-btn">
                      <ion-icon name="location"></ion-icon>

                      <address>Gasabo-Nduba, Kigali-Rwanda</address>
                    </button>
                  </div>
                </figure>

                <div class="blog-content">
                  <div className="card-price -mt-4 pb-3">
                    <strong>$ 34,900</strong>
                  </div>
                  <div class="blog-content-top">
                    <ul class="card-meta-list">
                      <li>
                        <a class="card-meta-link">
                          <i class="ri-shape-2-line text-[#ff5a3d]"></i>

                          <span>400 sqm</span>
                        </a>
                      </li>

                      <li>
                        <a class="card-meta-link">
                          <ion-icon name="pricetags"></ion-icon>

                          <span>1/12/21/12/76690</span>
                        </a>
                      </li>
                    </ul>

                    <h3 class="h3 blog-title">
                      <a className="line-clamp-2">Land for sale</a>
                    </h3>
                  </div>

                  <div class="blog-content-bottom">
                    <div class="publish-date">
                      <ion-icon name="calendar"></ion-icon>

                      <time datetime="2022-27-04">Apr 27, 2022</time>
                    </div>

                    {/* <a class="read-more-btn">Read More</a> */}
                  </div>
                </div>
              </div>
            </li>

            <li>
              <div class="blog-card" onClick={() => navigate("details")}>
                <figure class="card-banner">
                  <img src={PlotForSale} alt="Land for sale" class="w-100" />
                  <div class="card-badge orange">For Sale</div>
                  <div class="banner-actions">
                    <button class="banner-actions-btn">
                      <ion-icon name="location"></ion-icon>

                      <address>Gasabo-Nduba, Kigali-Rwanda</address>
                    </button>
                  </div>
                </figure>

                <div class="blog-content">
                  <div className="card-price -mt-4 pb-3">
                    <strong>$ 34,900</strong>
                  </div>
                  <div class="blog-content-top">
                    <ul class="card-meta-list">
                      <li>
                        <a class="card-meta-link">
                          <i class="ri-shape-2-line text-[#ff5a3d]"></i>

                          <span>400 sqm</span>
                        </a>
                      </li>

                      <li>
                        <a class="card-meta-link">
                          <ion-icon name="pricetags"></ion-icon>

                          <span>1/12/21/12/76690</span>
                        </a>
                      </li>
                    </ul>

                    <h3 class="h3 blog-title">
                      <a className="line-clamp-2">Land for sale</a>
                    </h3>
                  </div>

                  <div class="blog-content-bottom">
                    <div class="publish-date">
                      <ion-icon name="calendar"></ion-icon>

                      <time datetime="2022-27-04">Apr 27, 2022</time>
                    </div>

                    {/* <a class="read-more-btn">Read More</a> */}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </Helmet>
  );
};

export default Land;

import React from "react";

const TabItem = ({ tabName, isSelected, handleClick }) => {
  return (
    <li className="w-full focus-within:z-10">
      <a
        href={`#${tabName}`}
        className={`${
          isSelected ? "bg-[#ff5a3d] text-[#fff] text-xl" : ""
        } inline-block w-full p-4 active outline-none rounded`}
        onClick={() => handleClick(tabName)}
      >
        {tabName}
      </a>
    </li>
  );
};

export default TabItem;

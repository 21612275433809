import React from "react";
import { Container } from "reactstrap";
import Helmet from "../../components/Helmet/Helmet";
import BookingForm from "../../components/BookingForm";
import "../../assets/css/carIndex.css";
import "remixicon/fonts/remixicon.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommonSection from "../../components/CommonSection";

const LandDetails = () => {
  return (
    <Helmet title={"Land"}>
      <CommonSection title={"LAND FOR SALE"} bgImage="common__section_land" />
      <section className="mb-10 mt-16">
        <Container>
          <div className="grid md:grid-cols-2 gap-x-10 relative">
            <img
              src={
                "https://apnapyaraghar.com/wp-content/uploads/2023/07/Plot-For-Sale-in-Jayanagar-.jpg"
              }
              alt=""
              className="w-100"
            />
            <div class="card-badge orange">For Sale</div>

            <div className="car__info">
              <h2 className="section__title">{"Land for sale"}</h2>

              <div className="flex items-center gap-5 mb-4 mt-3">
                <h6 className="rent__price fw-bold fs-4">
                  <strong>$34,900</strong> ==== Residentail Plot
                </h6>
              </div>

              <p className="section__description">
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                text commonly used to demonstrate the document
              </p>

              <div
                className="flex xitems-center flex-col mt-4"
                style={{ columnGap: "2.8rem" }}
              >
                <span className="flex items-center gap-1 section__description">
                  Area: 700 sqm
                </span>
                <span className="flex items-center gap-1 section__description">
                  UPI: 1/12/12/21/8752
                </span>
                <span className="flex items-center gap-1 section__description">
                  Electricity and water Available
                </span>
                <span className="flex gap-x-3 section__description">
                  Location:{" "}
                  <ul>
                    <li>Country: Rwanda</li>
                    <li>Province: Kigali</li>
                    <li>District: Kicukiro</li>
                    <li>Sector: Kagarama</li>
                    <li>Cell: Kagarama I</li>
                  </ul>{" "}
                </span>

                {/* <span className="flex gap-x-3 section__description">
                  Near by:{" "}
                  <ul>
                    <li>1km from hospital</li>
                    <li>1km from bus park</li>
                    <li>Kabuga market</li>
                  </ul>{" "}
                </span> */}
                <span className="flex items-center gap-1 section__description">
                  Land Owner: +250 781 917 268 / +250 788 588 512
                </span>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-x-10">
            <div className="booking-info mt-5">
              <h5 className="mb-4 fw-bold">Contact Us</h5>
              <BookingForm />
            </div>
          </div>
        </Container>
      </section>
    </Helmet>
  );
};

export default LandDetails;

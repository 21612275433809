import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import "../assets/css/contact.css";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/CommonSection";
import { Button, Form, Input } from "antd";
import { sendMessageAction } from "../store/services/actions";
import { useDispatch, useSelector } from "react-redux";

const socialLinks = [
  {
    url: "#",
    icon: "ri-facebook-line",
  },
  {
    url: "#",
    icon: "ri-instagram-line",
  },
  {
    url: "#",
    icon: "ri-linkedin-line",
  },
  {
    url: "#",
    icon: "ri-twitter-line",
  },
];

const Contact = () => {
  const { services } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    const res = sendMessageAction({ ...values })(dispatch);
    if (res) {
      form.resetFields();
    }
  };

  return (
    <Helmet title="Contact">
      <CommonSection title="Contact Us" bgImage="common__section_contact" />
      <section className="pb-10">
        <Container>
          <div className="flex flex-col-reverse md:flex-row space-x-6">
            <div className="lg:w-1/2 md:w-1/2">
              <h6 className="font-bold mb-4 text-white">Get In Touch</h6>
              <Form form={form} onFinish={onFinish}>
                <Form.Item
                  className="contact__form"
                  name="names"
                  rules={[
                    {
                      required: true,
                      message: "field required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Your Name"
                    type="text"
                    style={{ height: 45 }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "field required!",
                      whitespace: true,
                    },
                  ]}
                  className="contact__form"
                >
                  <Input
                    placeholder="Email"
                    type="email"
                    style={{ height: 45 }}
                  />
                </Form.Item>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "field required!",
                      whitespace: true,
                    },
                  ]}
                  className="contact__form"
                >
                  <Input
                    placeholder="Phone"
                    type="text"
                    style={{ height: 45 }}
                  />
                </Form.Item>
                <Form.Item
                  name="subject"
                  rules={[
                    {
                      required: true,
                      message: "field required!",
                      whitespace: true,
                    },
                  ]}
                  className="contact__form"
                >
                  <Input
                    placeholder="Subject"
                    type="text"
                    style={{ height: 45, marginTop: 20 }}
                  />
                </Form.Item>
                <Form.Item
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "field required!",
                      whitespace: true,
                    },
                  ]}
                  className="contact__form"
                >
                  <Input.TextArea
                    style={{ marginTop: 20 }}
                    rows="5"
                    placeholder="Message"
                    className="textarea"
                  ></Input.TextArea>
                </Form.Item>

                <Button
                  loading={services?.isFetching}
                  type="primary"
                  htmlType="submit"
                  style={{ height: 40, width: 150 }}
                >
                  Send Message
                </Button>
              </Form>
            </div>

            <div className="lg:w-5/12 md:w-5/12 md:mt-10">
              <div className="contact__info">
                <h6 className="fw-bold">Contact Information</h6>
                <p className="section__description mb-0">
                  201 Room, Rubangura House, Kigali-Rwanda
                </p>
                <div className="flex items-center gap-2">
                  <h6 className="fs-6 mb-0">Phone:</h6>
                  <p className="section__description mb-0">+250 788 474 820</p>
                </div>

                <div className="flex items-center gap-2">
                  <h6 className="mb-0 fs-6">Email:</h6>
                  <p className="section__description mb-0">
                    info@cbrsrwanda.com
                  </p>
                </div>

                <h6 className="fw-bold mt-4">Follow Us</h6>

                <div className="flex items-center gap-4 mt-3">
                  {socialLinks.map((item, index) => (
                    <Link
                      to={item.url}
                      key={index}
                      className="social__link-icon"
                    >
                      <i class={item.icon}></i>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Helmet>
  );
};

export default Contact;

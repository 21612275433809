import React, { useEffect } from "react";
import { Col, Container } from "reactstrap";
// import "../assets/css/contact.css";
import CarItem from "../../components/card/CarItem";
// import { carData } from "../../assets/data/DammyData";
import Helmet from "../../components/Helmet/Helmet";
import CommonSection from "../../components/CommonSection";
import { useDispatch, useSelector } from "react-redux";
import { getAllCarsAction } from "../../store/cars/actions";

const AllCars = () => {
  const { cars } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    getAllCarsAction("?isPublished=true")(dispatch);
  }, [dispatch]);

  return (
    <Helmet title="Contact">
      <CommonSection title="Available Cars" bgImage="common__section_cars" />
      <section class="blog">
        <Container>
          <Col lg="12" className="text-center mb-5 lg:w-full">
            <h6 className="section__subtitle">Come with</h6>
            <h2 className="section__title">Hot Offers</h2>
          </Col>
          <div className="gap-3 grid lg:grid-cols-3 md:grid-cols-2 w-full">
            {cars?.all?.data?.map((item) => (
              <CarItem item={item} />
            ))}
          </div>
        </Container>
      </section>
    </Helmet>
  );
};

export default AllCars;

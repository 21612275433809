import { configureStore } from "@reduxjs/toolkit";
import layoutReducer from "./layout";
import carsReducer from "./cars";
import houseReducer from "./house";
import landReducer from "./land";
import servicesReducer from "./services";

const store = configureStore({
  reducer: {
    layout: layoutReducer,
    cars: carsReducer,
    house: houseReducer,
    land: landReducer,
    services: servicesReducer,
  },
});

export default store;

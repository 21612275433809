import React, { useState } from "react";
import TabItem from "./TabItem";
import { activeTabsAction } from "../../store/layout/actions";
import { useDispatch, useSelector } from "react-redux";

const Tabs = () => {
  const { layout } = useSelector((state) => state);
  const [selectedTab, setSelectedTab] = useState(layout?.activeTabs);

  const dispatch = useDispatch();

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
    activeTabsAction(tabName)(dispatch);
  };

  return (
    <div className="w-full md:w-3/5 mx-auto" id="property">
      <ul className="text-md font-medium text-center rounded-lg shadow flex">
        <TabItem
          tabName="Houses"
          isSelected={selectedTab === "Houses"}
          handleClick={handleTabClick}
        />
        <TabItem
          tabName="Cars"
          isSelected={selectedTab === "Cars"}
          handleClick={handleTabClick}
        />
        <TabItem
          tabName="Land"
          isSelected={selectedTab === "Land"}
          handleClick={handleTabClick}
        />
      </ul>
    </div>
  );
};

export default Tabs;

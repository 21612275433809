import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo_new.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const [openNavBar, setOpenNavBar] = useState(false);

  const toggleNavbarOpen = () => {
    setIsActive(true);
  };

  const toggleNavbarClose = () => {
    setIsActive(false);
  };

  const handleScroll = () => {
    if (window.scrollY >= 400) {
      setOpenNavBar(true);
    } else {
      setOpenNavBar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header class={`header ${openNavBar ? "active" : ""}`} data-header>
      <div class="overlay" data-overlay></div>
      <div class="header-top">
        <div class="container">
          <ul class="header-top-list">
            <li>
              <a
                href="mailto:info@trinityservicehub.rw"
                class="header-top-link"
              >
                <ion-icon name="mail-outline"></ion-icon>

                <span>info@cbrsrwanda.com</span>
              </a>
            </li>

            <li>
              <a href="#h" class="header-top-link">
                <ion-icon name="location-outline"></ion-icon>

                <address>201 Room, Rubangura House, Kigali-Rwanda</address>
              </a>
            </li>
          </ul>

          <div class="wrapper">
            <ul class="header-top-social-list"></ul>

            <a
              href="https://visa.cbrsltd.com/"
              target="_blank"
              className="header-top-btn"
              rel="noreferrer"
            >
              Visa Application
            </a>
          </div>
        </div>
      </div>
      <div class="header-bottom">
        <div class="container">
          <Link to={"/"}>
            <span class="logo">
              <img src={Logo} alt="trinity service logo" />
            </span>
          </Link>
          <nav
            data-navbar
            className={isActive ? "active navbar" : "navbar"}
            onClick={toggleNavbarClose}
          >
            <div class="navbar-top">
              <Link to={"/"}>
                <span class="logo">
                  <img src={Logo} alt="trinity service logo" />
                </span>
              </Link>

              <button
                class="nav-close-btn"
                data-nav-close-btn
                aria-label="Close Menu"
                onClick={toggleNavbarClose}
              >
                <ion-icon name="close-outline"></ion-icon>
              </button>
            </div>

            <div class="navbar-bottom">
              <ul class="navbar-list cursor-pointer">
                <li>
                  <Link to={"/"}>
                    <span class="navbar-link" data-nav-link>
                      Home
                    </span>
                  </Link>
                </li>

                <li>
                  <a href="#about" class="navbar-link" data-nav-link>
                    About
                  </a>
                </li>

                <li>
                  <a href="#service" class="navbar-link" data-nav-link>
                    Service
                  </a>
                </li>

                <li>
                  <a href="#property" class="navbar-link" data-nav-link>
                    Property
                  </a>
                </li>

                <li>
                  <Link to={"/contact"}>
                    <span class="navbar-link" data-nav-link>
                      Contact
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          <div class="header-bottom-actions">
            <button class="header-bottom-actions-btn" aria-label="Search">
              <ion-icon name="search-outline"></ion-icon>
              <span>Search</span>
            </button>
            <button
              class="header-bottom-actions-btn"
              data-nav-open-btn
              aria-label="Open Menu"
              onClick={toggleNavbarOpen}
            >
              <ion-icon name="menu-outline"></ion-icon>
              <span>Menu</span>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;

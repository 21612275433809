import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/car-item.css";

const CarItem = ({ item }) => {
  return (
    <div className="xmb-5 xlg:w-1/2 xmd:w-1/4 xsm:w-1/2 flex flex-row">
      <div className="car__item">
        <div className="car__img">
          <img src={item?.picture?.at(0)} alt="" className="w-100" />
        </div>

        <div className="car__item-content mt-4">
          <h4 className="section__title text-center">{item?.model}</h4>
          <h6 className="rent__price text-center">
            {`${item?.currency} ${item?.price?.toLocaleString()}`}
            {`${item?.carCategory === "Rent" ? `/${item?.rentPeriode}` : ""}`}
          </h6>

          <div className="car__item-info flex items-center justify-between mt-3 mb-4">
            <span className="flex items-center gap-1">
              <i class="ri-car-line"></i> {item?.groupSize}
            </span>
            <span className="flex items-center gap-1">
              <i class="ri-car-line"></i> {item?.oilType}
            </span>
            <span className="flex items-center gap-1">
              <i class="ri-settings-2-line"></i> {item?.carType}
            </span>
            <span className="flex items-center gap-1">
              <i class="ri-timer-flash-line"></i> {item?.kmpl}
            </span>
          </div>
          <div className="flex">
            <button className="w-1/2 car__item-btn car__btn-rent text-center">
              <Link to={`/cars/details/${item?._id}`}>Rent</Link>
            </button>

            <button className=" w-1/2 car__item-btn car__btn-details text-center">
              <Link to={`/cars/details/${item?._id}`}>Details</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarItem;

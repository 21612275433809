import { myHouse } from ".";
import { getAllHousesService, getOneHouseService } from "./services";

export const getAllHousesAction = (query) => {
  return async (dispatch) => {
    try {
      dispatch(myHouse.setIsFetching(true));
      const res = await getAllHousesService(query);
      if (res?.status === 200) {
        await dispatch(myHouse.setAll(res));
        await dispatch(myHouse.setIsFetching(false));
        return true;
      }
      dispatch(myHouse.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneHouseAction = (itemId) => {
  return async (dispatch) => {
    try {
      dispatch(myHouse.setIsFetching(true));
      const res = await getOneHouseService(itemId);
      if (res?.status === 200) {
        await dispatch(myHouse.setSelected(res));
        await dispatch(myHouse.setIsFetching(false));
        return true;
      }
      dispatch(myHouse.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};


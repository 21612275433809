import React from "react";
import { Container, Col } from "reactstrap";
// import { carData } from "../../assets/data/DammyData";
import CarItem from "../../components/card/CarItem";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CardSkeleton from "../../components/skeleton/CardSkeleton";

const Cars = () => {
  const { cars } = useSelector((state) => state);
  const navigate = useNavigate();

  return (
    <section class="blog">
      {cars?.isFetching ? (
        <div className="flex flex-col md:flex-row max-w-[1000px] mx-auto">
          {[1, 2, 3]?.map((el) => (
            <CardSkeleton />
          ))}
        </div>
      ) : (
        <Container>
          <Col lg="12" className="text-center mb-5 lg:w-full">
            <h6 className="section__subtitle">Come with</h6>
            <h2 className="section__title">Hot Offers</h2>
          </Col>
          <div className="gap-3 grid lg:grid-cols-3 md:grid-cols-2 w-full">
            {cars?.all?.data?.map((item) => (
              <CarItem item={item} />
            ))}
          </div>
          <button
            class="header-top-btn mt-2 rounded"
            onClick={() => navigate("/cars")}
          >
            Find More...
          </button>
        </Container>
      )}
    </section>
  );
};

export default Cars;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CardSkeleton from "../components/skeleton/CardSkeleton";
// import { Carousel } from "antd";

const Property = () => {
  const navigate = useNavigate();
  const { house } = useSelector((state) => state);

  return (
    <section class="xproperty blog" id="property">
      {house?.isFetching ? (
        <div className="flex flex-col md:flex-row max-w-[1000px] mx-auto">
          {[1, 2, 3]?.map((el) => (
            <CardSkeleton />
          ))}
        </div>
      ) : (
        <div class="container">
          <ul class="property-list xhas-scrollbar gap-x-4 gap-y-6 grid md:grid-cols-3">
            {house?.all?.data?.map((d) => (
              <li>
                <div
                  class="property-card"
                  onClick={() => navigate(`/houses/details/${d?._id}`)}
                >
                  <figure class="card-banner">
                    {/* <Carousel autoplay style={{ width: 250 }}>
                    {d?.picture?.map((im) => (
                      <img src={im} alt="" />
                    ))}
                  </Carousel> */}

                    <img
                      src={d?.picture?.at(0)}
                      alt="Luxury villa in Rego Park"
                      class="w-100"
                    />

                    <div
                      class={`card-badge ${
                        d?.houseType === "Rent" ? "green" : "orange"
                      }`}
                    >
                      {`For ${d?.houseType}`}
                    </div>

                    <div class="banner-actions">
                      <button class="banner-actions-btn">
                        <ion-icon name="location"></ion-icon>
                        <address>{`${d?.address?.cell} - ${d?.address?.sector}, ${d?.address?.district} - ${d?.address?.province}`}</address>
                      </button>
                    </div>
                  </figure>

                  <div class="card-content">
                    <div class="card-price">
                      <strong>{`${
                        d?.currency
                      } ${d?.price?.toLocaleString()}`}</strong>
                      {`${d?.houseType === "Rent" ? `/${d?.rentPeriode}` : ""}`}
                    </div>
                    <h3 class="h3 card-title">
                      <a>{d?.name}</a>
                    </h3>
                    <p class="card-text line-clamp-2">{d?.desc}</p>

                    <ul class="card-list">
                      <li class="card-item">
                        <strong>{d?.bedRooms}</strong>
                        <ion-icon name="bed-outline"></ion-icon>
                        <span>Bedrooms</span>
                      </li>

                      <li class="card-item">
                        <strong>{d?.bathRooms}</strong>
                        <ion-icon name="man-outline"></ion-icon>
                        <span>Bathrooms</span>
                      </li>

                      <li class="card-item">
                        <strong>Available</strong>
                        <ion-icon name="square-outline"></ion-icon>
                        <span>Packing</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <button
            class="header-top-btn mt-2 rounded"
            onClick={() => navigate("/houses")}
          >
            Find More...
          </button>
        </div>
      )}
    </section>
  );
};

export default Property;

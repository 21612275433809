import React from "react";

import Slider from "react-slick";
import { Container } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "../assets/css/hero-slider.css";
import "../assets/css/carIndex.css";
import "remixicon/fonts/remixicon.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Banner from "../assets/images/hero-banner.png";

const HeroSlider = () => {
  const navigate = useNavigate();
  const settings = {
    fade: true,
    speed: 5000,
    autoplaySpeed: 7000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };
  return (
    <Slider {...settings} className="hero__slider">
      <div className="slider__item slider__item-01 mt0">
        <Container>
          <div className="slider__content ">
            <h4 className="lg:text-3xl text-2xl text-white mb-4">
              Buy or Rent your dream car
            </h4>
            <h2 class="text-2xl lg:text-4xl text-white">
              Welcome to CBRS Property Ltd, your ultimate destination for
              seamless automotive solutions
            </h2>

            <Link to="/cars">
              <button className="btn reserve__btn mt-4">Reserve Now</button>
            </Link>
          </div>
        </Container>
      </div>
      <section class="hero" id="home">
        <div class="container">
          <div class="hero-content">
            <h2 class="h1 hero-title">Find Your Dream House By Us</h2>

            <p class="hero-text">
              Your trusted partner in real estate, offering personalized
              services to help you discover the perfect home tailored to your
              needs and preferences. Explore our extensive listings and
              experience seamless transactions, guiding you every step of the
              way towards your dream property
            </p>

            <button class="btn" onClick={() => navigate("/houses")}>
              Make An Enquiry
            </button>
          </div>

          <figure class="hero-banner">
            <img src={Banner} alt="Modern house model" class="w-100" />
          </figure>
        </div>
      </section>
      <div className="slider__item slider__item-03 mt0">
        <Container>
          <div className="slider__content ">
            <h4 className="lg:text-3xl text-2xl text-white mb-4">
              SOLUTION FOR ALL TYPE OF VISAS
            </h4>
            <h2 class="text-2xl lg:text-4xl text-white">
              Immigration Process Starts Here
            </h2>

            <a
              href="https://visa.cbrsltd.com/"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn reserve__btn mt-4">Check now</button>
            </a>
          </div>
        </Container>
      </div>
    </Slider>
  );
};

export default HeroSlider;

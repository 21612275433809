import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllLandsService = async (query) => {
  return HttpRequest.get(`${SERVER_URL}/land${query}`, {
  });
};

export const getOneLandService = async (itemId) => {
  return HttpRequest.get(`${SERVER_URL}/land/one/${itemId}`, {
  });
};


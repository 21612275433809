/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import Property6 from "../../assets/images/r1.jpg";
import Helmet from "../../components/Helmet/Helmet";
import CommonSection from "../../components/CommonSection";
import { useNavigate } from "react-router-dom";
import { getAllHousesAction } from "../../store/house/actions";
import { useDispatch, useSelector } from "react-redux";
// import { Carousel } from "antd";

const Houses = () => {
  const { house } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getAllHousesAction("?isPublished=true")(dispatch);
  }, [dispatch]);

  return (
    <Helmet title="Contact">
      <CommonSection
        title="House for Sale and Rent"
        bgImage="common__section_house"
      />
      <section class="blog" id="property">
        <div class="container">
          <p class="section-subtitle">Properties</p>

          {/* <h2 class="h2 section-title">Featured Listings</h2> */}

          <ul class="property-list gap-x-4 gap-y-6 grid md:grid-cols-3">
            {house?.all?.data?.map((d) => (
              <li>
                <div
                  class="property-card"
                  onClick={() => navigate(`details/${d?._id}`)}
                >
                  <figure class="card-banner">
                    <img
                      src={Property6}
                      alt="Luxury villa in Rego Park"
                      class="w-100"
                    />
                    {/* <Carousel autoplay>
                      {d?.picture?.map((im) => (
                        <img src={im} alt="" />
                      ))}
                    </Carousel> */}

                    <div
                      class={`card-badge ${
                        d?.houseType === "Rent" ? "green" : "orange"
                      }`}
                    >
                      {`For ${d?.houseType}`}
                    </div>

                    <div class="banner-actions">
                      <button class="banner-actions-btn">
                        <ion-icon name="location"></ion-icon>
                        <address>{`${d?.address?.cell} - ${d?.address?.sector}, ${d?.address?.district} - ${d?.address?.province}`}</address>
                      </button>
                    </div>
                  </figure>

                  <div class="card-content">
                    <div class="card-price">
                      <strong>{`${
                        d?.currency
                      } ${d?.price?.toLocaleString()}`}</strong>
                      {`${d?.houseType === "Rent" ? `/${d?.rentPeriode}` : ""}`}
                    </div>
                    <h3 class="h3 card-title">
                      <a>{d?.name}</a>
                    </h3>
                    <p class="card-text line-clamp-2">{d?.desc}</p>

                    <ul class="card-list">
                      <li class="card-item">
                        <strong>{d?.bedRooms}</strong>
                        <ion-icon name="bed-outline"></ion-icon>
                        <span>Bedrooms</span>
                      </li>

                      <li class="card-item">
                        <strong>{d?.bathRooms}</strong>
                        <ion-icon name="man-outline"></ion-icon>
                        <span>Bathrooms</span>
                      </li>

                      <li class="card-item">
                        <strong>Available</strong>
                        <ion-icon name="square-outline"></ion-icon>
                        <span>Packing</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Helmet>
  );
};

export default Houses;

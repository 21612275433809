import { myLand } from ".";
import { getAllLandsService, getOneLandService } from "./services";


export const getAllLandAction = (query) => {
  return async (dispatch) => {
    try {
      dispatch(myLand.setIsFetching(true));
      const res = await getAllLandsService(query);
      if (res?.status === 200) {
        await dispatch(myLand.setAll(res));
        await dispatch(myLand.setIsFetching(false));
        return true;
      }
      dispatch(myLand.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneLandAction = (itemId) => {
  return async (dispatch) => {
    try {
      dispatch(myLand.setIsFetching(true));
      const res = await getOneLandService(itemId);
      if (res?.status === 200) {
        await dispatch(myLand.setSelected(res));
        await dispatch(myLand.setIsFetching(false));
        return true;
      }
      dispatch(myLand.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
import { notification } from "antd";
import { myService } from ".";
import { bookItemService, getAllService, sendMessageService } from "./services";

export const getAllServicesAction = (query) => {
  return async (dispatch) => {
    try {
      dispatch(myService.setIsFetching(true));
      const res = await getAllService(query);
      if (res?.status === 200) {
        await dispatch(myService.setAll(res));
        await dispatch(myService.setIsFetching(false));
        return true;
      }
      dispatch(myService.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const sendMessageAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch(myService.setIsFetching(true));
      const res = await sendMessageService(data);
      if (res?.status === 201) {
        notification.success({ message: "Sent successfully" });
        await dispatch(myService.setIsFetching(false));
        return true;
      }
      dispatch(myService.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const bookItemAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch(myService.setIsFetching(true));
      const res = await bookItemService(data);
      if (res?.status === 201) {
        notification.success({ message: "Sent successfully" });
        await dispatch(myService.setIsFetching(false));
        return true;
      }
      dispatch(myService.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
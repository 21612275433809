import React from "react";
import { useSelector } from "react-redux";

const Service = () => {
  const { services } = useSelector((state) => state);
  console.log("services?.all?.data", services?.all?.data);

  return (
    <section class="service" id="service">
      <div class="container">
        <p class="section-subtitle">Our Services</p>

        <h2 class="h2 section-title">Our Main Focus</h2>

        <ul class="service-list">
          {services?.all?.data?.map((d) => (
            <li className="w-full">
              <div class="service-card">
                <div className="min-w-full">
                  <h3 class="h3 card-title">
                    <span>{d?.name}</span>
                  </h3>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Service;

import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllHousesService = async (query) => {
  return HttpRequest.get(`${SERVER_URL}/house${query}`, {
  });
};

export const getOneHouseService = async (itemId) => {
  return HttpRequest.get(`${SERVER_URL}/house/one/${itemId}`, {
  });
};

import React from "react";
import { Container } from "reactstrap";
import "../assets/css/common-section.css";

const CommonSection = ({ title, bgImage }) => {
  return (
    <section className={`${bgImage} mb-5`}>
      <Container className="text-center">
        <h1 className="font-bold text-2xl text-white uppercase">{title}</h1>
      </Container>
    </section>
  );
};

export default CommonSection;

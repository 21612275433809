import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllCarsService = async (query) => {
  return HttpRequest.get(`${SERVER_URL}/cars${query}`, {
  });
};

export const getOneCarsService = async (itemId) => {
  return HttpRequest.get(`${SERVER_URL}/cars/one/${itemId}`, {
  });
};


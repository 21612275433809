import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import CarDetails from "../pages/car/CarDetails";
import ContactPage from "../pages/ContactPage";
import AllCars from "../pages/car/Allcars";
import Land from "../pages/land/Land";
import Houses from "../pages/houses/House";
import LandDetails from "../pages/land/LandDetails";
import HouseDetails from "../pages/houses/HouseDetails";

// import PageNotFound from "../pages/PageNotFound";

const MainRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/cars" element={<AllCars />} />
      <Route path="/cars/details/:carId" element={<CarDetails />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/land" element={<Land />} />
      <Route path="/land/details" element={<LandDetails />} />
      <Route path="/houses" element={<Houses />} />
      <Route path="/houses/details/:houseId" element={<HouseDetails />} />
      {/* <Route path="/*" element={<PageNotFound />} /> */}
    </Routes>
  );
};

export default MainRoute;

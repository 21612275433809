import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllService = async (query) => {
  return HttpRequest.get(`${SERVER_URL}/service${query}`, {
  });
};

export const sendMessageService = async (data) => {
  return await HttpRequest.post(`${SERVER_URL}/message`, data);
};

export const bookItemService = async (data) => {
  return await HttpRequest.post(`${SERVER_URL}/book/item`, data);
};

import React, { useEffect } from "react";
import About from "./About";
import Service from "./Service";
import Property from "./Property";
import Land from "../components/Land";
import HeroSlider from "./HeroSlider";
import Cars from "./car/Cars";
import Tabs from "../components/tabs/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { getAllServicesAction } from "../store/services/actions";
import { getAllCarsAction } from "../store/cars/actions";
import { getAllHousesAction } from "../store/house/actions";
import { getAllLandAction } from "../store/land/actions";

const Home = () => {
  const { layout } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllCarsAction("?isPublished=true&limit=6&page=0")(dispatch);
    getAllHousesAction("?isPublished=true&limit=6&page=0")(dispatch);
    getAllLandAction("?isPublished=true&limit=6&page=0")(dispatch);
    getAllServicesAction("?isPublished=true&limit=15&page=0")(dispatch);
  }, [dispatch]);

  return (
    <article>
      <HeroSlider />
      <About />
      <Service />
      <Tabs />
      {layout?.activeTabs === "Houses" ? (
        <Property />
      ) : layout?.activeTabs === "Cars" ? (
        <Cars />
      ) : (
        <Land />
      )}

      {/* <Feactures /> */}

      <section class="cta">
        <div class="container">
          <div class="cta-card">
            <div class="card-content">
              <h2 class="h2 card-title">
                Looking for a dream home, Car or Land?
              </h2>

              <p class="card-text">We can help you realize your dreams</p>
            </div>

            {/* <button class="btn cta-btn">
              <span>Explore Properties</span>

              <ion-icon name="arrow-forward-outline"></ion-icon>
            </button> */}
          </div>
        </div>
      </section>
    </article>
  );
};

export default Home;

import React, { useEffect } from "react";
import { Container } from "reactstrap";
import Helmet from "../../components/Helmet/Helmet";
import { useParams } from "react-router-dom";
// import PaymentMethod from "../../components/PaymentMethod";
import BookingForm from "../../components/BookingForm";
import { carData } from "../../assets/data/DammyData";
import "../../assets/css/carIndex.css";
import "remixicon/fonts/remixicon.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommonSection from "../../components/CommonSection";
import { useDispatch, useSelector } from "react-redux";
import { getOneCarsAction } from "../../store/cars/actions";
import CardSkeleton from "../../components/skeleton/CardSkeleton";

const CarDetails = () => {
  const { carId } = useParams();
  const { cars } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (carId) {
      getOneCarsAction(carId)(dispatch);
    }
  }, [carId, dispatch]);

  const singleCarItem = carData;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [singleCarItem]);

  return (
    <Helmet title={"Cars"}>
      <CommonSection
        title={cars?.selected?.data?.model}
        bgImage="common__section_cars"
      />
      <section className="mb-10 mt-16">
        <Container>
          {cars?.isFetching ? (
            <CardSkeleton />
          ) : (
            <div className="grid md:grid-cols-2 gap-x-10">
              <img
                src={cars?.selected?.data?.picture?.at(0)}
                alt=""
                className="w-100"
              />

              <div className="car__info">
                {!cars?.isFetching && (
                  <h2 className="section__title">
                    {cars?.selected?.data?.model}
                  </h2>
                )}

                <div className="flex items-center gap-5 mb-4 mt-3">
                  <h6 className="rent__price fw-bold fs-4">
                    {`${
                      cars?.selected?.data?.currency
                    } ${cars?.selected?.data?.price?.toLocaleString()}`}
                    {`${
                      cars?.selected?.data?.carCategory === "Rent"
                        ? `/${cars?.selected?.data?.rentPeriode}`
                        : ""
                    }`}
                  </h6>

                  {cars?.selected?.data?.carCategory === "Rent" && (
                    <span className="flex items-center gap-2">
                      <span style={{ color: "#f9a826" }}>
                        <i class="ri-star-s-fill"></i>
                        <i class="ri-star-s-fill"></i>
                        <i class="ri-star-s-fill"></i>
                        <i class="ri-star-s-fill"></i>
                        <i class="ri-star-s-fill"></i>
                      </span>
                      (100% ratings)
                    </span>
                  )}
                </div>

                <p className="section__description">
                  {cars?.selected?.data?.desc}
                </p>

                <div
                  className="flex items-center mt-3"
                  style={{ columnGap: "4rem" }}
                >
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-roadster-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    {cars?.selected?.data?.brand}
                  </span>

                  <span className="flex items-center gap-1 section__description">
                    <i
                      class="ri-settings-2-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    {cars?.selected?.data?.carType}
                  </span>

                  <span className="flex items-center gap-1 section__description">
                    <i
                      class="ri-timer-flash-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    {cars?.selected?.data?.kmpl}
                  </span>
                </div>

                <div
                  className="flex items-center mt-3"
                  style={{ columnGap: "2.8rem" }}
                >
                  <span className="flex items-center gap-1 section__description">
                    <i class="ri-map-pin-line" style={{ color: "#f9a826" }}></i>{" "}
                    GPS Navigation
                  </span>

                  <span className="flex items-center gap-1 section__description">
                    <i
                      class="ri-wheelchair-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    Heated seats
                  </span>

                  <span className="flex items-center gap-1 section__description">
                    <i
                      class="ri-building-2-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    {`${cars?.selected?.data?.groupSize} People`}
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="grid md:grid-cols-2 gap-x-10">
            <div className="booking-info mt-5">
              <h5 className="mb-4 fw-bold ">Book Now</h5>
              <BookingForm />
            </div>

            {/* <div className="payment__info mt-5">
              <h5 className="mb-4 fw-bold ">Payment Information</h5>
              <PaymentMethod />
            </div> */}
          </div>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarDetails;

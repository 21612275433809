import { BrowserRouter } from "react-router-dom";
import Layout from "./components/layout/Layout"
import MainRoute from "./routes/MainRoute"
import ScrollToTop from "./components/ScrollToTop";
import WhatsApp from "./components/whatsapp/WhatsApp";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Layout>
        <MainRoute />
      </Layout>
      <WhatsApp />
    </BrowserRouter>
  );
}

export default App;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import LogoLight from "../../assets/images/logo_new_light.png"
import { Link, useNavigate } from 'react-router-dom'

const Footer = () => {
    const navigate = useNavigate()
    return (
        <footer class="footer">
            <div class="footer-top">
                <div class="container">
                    <div class="footer-brand">
                        <a class="logo cursor-pointer" onClick={() => navigate("/")}>
                            <img src={LogoLight} alt="Homeverse logo" />
                        </a>

                        {/* <p class="section-text">
                            Lorem Ipsum is simply dummy text of the and typesetting industry.
                            Lorem Ipsum is dummy text of the printing.
                        </p> */}

                        <ul class="contact-list">
                            <li>
                                <a class="contact-link">
                                    <ion-icon name="location-outline"></ion-icon>

                                    <address>201 Room, Rubangura House, Kigali-Rwanda</address>
                                </a>
                            </li>

                            <li>
                                <a href="tel:+0123456789" class="contact-link">
                                    <ion-icon name="call-outline"></ion-icon>

                                    <span>+250 788 474 820</span>
                                </a>
                            </li>

                            <li>
                                <a href="mailto:contact@trinityservicehub.rw" class="contact-link">
                                    <ion-icon name="mail-outline"></ion-icon>
                                    <span>info@cbrsrwanda.com</span>
                                </a>
                            </li>
                        </ul>

                        {/* <ul class="social-list">
                            <li>
                                <a class="social-link">
                                    <ion-icon name="logo-facebook"></ion-icon>
                                </a>
                            </li>

                            <li>
                                <a class="social-link">
                                    <ion-icon name="logo-twitter"></ion-icon>
                                </a>
                            </li>

                            <li>
                                <a class="social-link">
                                    <ion-icon name="logo-linkedin"></ion-icon>
                                </a>
                            </li>

                            <li>
                                <a class="social-link">
                                    <ion-icon name="logo-youtube"></ion-icon>
                                </a>
                            </li>
                        </ul> */}
                    </div>

                    <div class="footer-link-box">
                        <ul class="footer-list">
                            <li>
                                <p class="footer-list-title">Company</p>
                            </li>

                            <li>
                                <a href="#about" class="footer-link">About</a>
                            </li>

                            <li>
                                <Link to={"/contact"}>
                                    <a class="footer-link">Contact us</a>
                                </Link>
                            </li>
                        </ul>

                        <ul class="footer-list">
                            <li>
                                <p class="footer-list-title">Services</p>
                            </li>

                            <li>
                                <a href="#service" class="footer-link">Service</a>
                            </li>

                            <li>
                                <a href="#property" class="footer-link">Properties</a>
                            </li>
                        </ul>

                        <ul class="footer-list">

                        </ul>
                    </div>
                </div>
            </div>

            <div class="footer-bottom">
                <div class="container">
                    <p class="copyright">
                        &copy; 2024 <a>CBRS Property Ltd</a>. All Rights Reserved
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer

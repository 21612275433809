import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "layout",
  initialState: {
    isLoafding: false,
    activeTabs: "Houses",
  },
  reducers: {
    setIsLoading(state, action) {
      state.isLoafding = action.payload;
    },
    setActiveTabs(state, action) {
      state.activeTabs = action.payload;
    },
  },
});

export const myLayoutActions = mySlice.actions;

export default mySlice.reducer;

import React from "react";
import About1 from "../assets/images/about-banner-1.png";
import About2 from "../assets/images/about-banner-2.jpg";

const About = () => {
  return (
    <section class="about" id="about">
      <div class="container">
        <figure class="about-banner">
          <img src={About1} alt="House interior" />

          <img src={About2} alt="House interior" class="abs-img" />
        </figure>

        <div class="about-content">
          <p class="section-subtitle">About Us</p>

          <h2 class="h2 section-title">
            The Leading Real Estate Rental Marketplace.
          </h2>

          <p class="about-text">
            Over 39,000 people work for us in more than 70 countries all over
            the This breadth of global coverage, combined with specialist
            services
          </p>

          <ul class="about-list">
            <li class="about-item">
              <div class="about-item-icon">
                <ion-icon name="home-outline"></ion-icon>
              </div>

              <p class="about-item-text">Smart Home Design</p>
            </li>

            <li class="about-item">
              <div class="about-item-icon">
                <ion-icon name="leaf-outline"></ion-icon>
              </div>

              <p class="about-item-text">Beautiful Scene Around</p>
            </li>

            <li class="about-item">
              <div class="about-item-icon">
                <ion-icon name="wine-outline"></ion-icon>
              </div>

              <p class="about-item-text">Exceptional Lifestyle</p>
            </li>

            <li class="about-item">
              <div class="about-item-icon">
                <ion-icon name="shield-checkmark-outline"></ion-icon>
              </div>

              <p class="about-item-text">Complete 24/7 Security</p>
            </li>
          </ul>
          {/* 
          <p class="callout">
            "Enimad minim veniam quis nostrud exercitation llamco laboris. Lorem
            ipsum dolor sit amet"
          </p> */}
        </div>
      </div>
    </section>
  );
};

export default About;

import { myCars } from ".";
import { getAllCarsService, getOneCarsService } from "./services";

export const getAllCarsAction = (query) => {
  return async (dispatch) => {
    try {
      dispatch(myCars.setIsFetching(true));
      const res = await getAllCarsService(query);
      if (res?.status === 200) {
        await dispatch(myCars.setAll(res));
        await dispatch(myCars.setIsFetching(false));
        return true;
      }
      dispatch(myCars.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneCarsAction = (itemId) => {
  return async (dispatch) => {
    try {
      dispatch(myCars.setIsFetching(true));
      const res = await getOneCarsService(itemId);
      if (res?.status === 200) {
        await dispatch(myCars.setSelected(res));
        await dispatch(myCars.setIsFetching(false));
        return true;
      }
      dispatch(myCars.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

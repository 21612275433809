import React from "react";
import "../assets/css/booking-form.css";
import { useLocation, useParams } from "react-router-dom";
import { bookItemAction } from "../store/services/actions";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, Select, notification } from "antd";

const BookingForm = () => {
  const [form] = Form.useForm();
  const { services } = useSelector((state) => state);
  const { houseId, landId, carId } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const path = pathname.split("/")[1];

  const onFinish = async (values) => {
    const name = `${values.fName} ${values.lName}`;
    const payload = {
      ...values,
      house: houseId,
      land: landId,
      car: carId,
      user: { ...values, names: name },
      address: values,
    };

    if (houseId || landId || carId) {
      const res = await bookItemAction(payload)(dispatch);
      if (res) {
        form.resetFields();
      }
    } else {
      notification.success({ message: "Sent Successfully" });
      form.resetFields();
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item
        name="fName"
        rules={[
          {
            required: true,
            message: "This field is required",
            whitespace: true,
          },
        ]}
        className="booking__form inline-block me-4 mb-4"
      >
        <Input type="text" placeholder="First Name" />
      </Form.Item>
      <Form.Item
        name="lName"
        rules={[
          {
            required: true,
            message: "This field is required",
            whitespace: true,
          },
        ]}
        className="booking__form inline-block ms-1 mb-4"
      >
        <Input type="text" placeholder="Last Name" />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "This field is required",
            whitespace: true,
          },
        ]}
        className="booking__form inline-block me-4 mb-4"
      >
        <Input type="email" placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: "This field is required",
            whitespace: true,
          },
        ]}
        className="booking__form inline-block ms-1 mb-4"
      >
        <Input type="number" placeholder="Phone Number" />
      </Form.Item>
      {path !== "cars" && (
        <Form.Item className="">
          <Input
            style={{ height: 40, marginTop: 10 }}
            type="text"
            placeholder="Subject"
          />
        </Form.Item>
      )}

      {path === "cars" && (
        <>
          <Form.Item
            name="from"
            rules={[
              {
                required: true,
                message: "This field is required",
                whitespace: true,
              },
            ]}
            className="booking__form inline-block me-4 mb-4"
          >
            <Input type="text" placeholder="From Address" />
          </Form.Item>
          <Form.Item
            name="to"
            rules={[
              {
                required: true,
                message: "This field is required",
                whitespace: true,
              },
            ]}
            className="booking__form inline-block ms-1 mb-4"
          >
            <Input type="text" placeholder="To Address" />
          </Form.Item>

          <Form.Item
            name="nomberPerson"
            rules={[
              {
                required: true,
                message: "This field is required",
                whitespace: true,
              },
            ]}
            className="booking__form inline-block me-4 mb-4"
          >
            <Select defaultValue={"Number of people"} style={{ height: 40 }}>
              {["1", "2", "3", "4", "5+"]?.map((d) => (
                <Select.Option value={d}>{`${d} Person`}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="date"
            className="booking__form inline-block me-4 mb-4"
          >
            <Input type="date" placeholder="Journey Date" />
          </Form.Item>
          <Form.Item
            name="time"
            rules={[
              {
                required: true,
                message: "This field is required",
                whitespace: true,
              },
            ]}
            className="booking__form inline-block xms-1 xmb-4"
          >
            <Input
              type="time"
              placeholder="Journey Time"
              className="time__picker"
            />
          </Form.Item>
        </>
      )}

      <Form.Item name="message">
        <Input.TextArea
          rows={5}
          type="textarea"
          className="textarea"
          placeholder="Write"
        ></Input.TextArea>
      </Form.Item>
      <div className="payment text-end mt-5">
        <Button
          loading={services?.isFetching}
          type="primary"
          htmlType="submit"
          style={{ height: 40, width: 100 }}
        >
          Send Now
        </Button>
      </div>
    </Form>
  );
};

export default BookingForm;

import React, { useEffect } from "react";
import { Container } from "reactstrap";
import Helmet from "../../components/Helmet/Helmet";
import BookingForm from "../../components/BookingForm";
// import Property1 from "../../assets/images/property-1.jpg";
import "../../assets/css/carIndex.css";
import "remixicon/fonts/remixicon.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommonSection from "../../components/CommonSection";
import { useParams } from "react-router-dom";
import { getOneHouseAction } from "../../store/house/actions";
import { useDispatch, useSelector } from "react-redux";
import CardSkeleton from "../../components/skeleton/CardSkeleton";
// import { Carousel } from "antd";

const HouseDetails = () => {
  const { house } = useSelector((state) => state);
  const { houseId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (houseId) {
      getOneHouseAction(houseId)(dispatch);
    }
  }, [houseId, dispatch]);

  return (
    <Helmet title={"House"}>
      <CommonSection
        title={`HOUSE FOR ${house?.selected?.data?.houseType}`}
        bgImage="common__section_house"
      />
      <section className="mb-10 mt-16">
        <Container>
          {house?.isFetching ? (
            <CardSkeleton />
          ) : (
            <div className="grid md:grid-cols-2 gap-x-10 relative">
              {/* <Carousel autoplay>
                {house?.selected?.data?.picture?.map((im) => (
                  <img src={im} alt="" />
                ))}
              </Carousel> */}
              <img
                src={house?.selected?.data?.picture?.at(0)}
                alt="Luxury villa in Rego Park"
                class="w-100"
              />

              <div
                class={`card-badge ${
                  house?.selected?.data?.houseType === "Rent"
                    ? "green"
                    : "orange"
                }`}
              >
                {`For ${house?.selected?.data?.houseType}`}
              </div>

              <div className="car__info">
                {!house?.isFetching && (
                  <h2 className="section__title">
                    {house?.selected?.data?.name}
                  </h2>
                )}

                <div className="flex items-center gap-5 mb-4 mt-3">
                  <h6 className="rent__price fw-bold fs-4">
                    <strong>{`${
                      house?.selected?.data?.currency
                    } ${house?.selected?.data?.price?.toLocaleString()}`}</strong>
                    {`${
                      house?.selected?.data?.houseType === "Rent"
                        ? `/${house?.selected?.data?.rentPeriode}`
                        : ""
                    }`}
                  </h6>

                  {house?.selected?.data?.houseType === "Rent" && (
                    <span className="flex items-center gap-2">
                      <span style={{ color: "#f9a826" }}>
                        <i class="ri-star-s-fill"></i>
                        <i class="ri-star-s-fill"></i>
                        <i class="ri-star-s-fill"></i>
                        <i class="ri-star-s-fill"></i>
                        <i class="ri-star-s-fill"></i>
                      </span>
                      (100 ratings)
                    </span>
                  )}
                </div>

                <p className="section__description">
                  {house?.selected?.data?.desc}
                </p>

                <div
                  className="flex items-center flex-wrap mt-4"
                  style={{ columnGap: "2.8rem" }}
                >
                  <span className="flex items-center gap-1 section__description">
                    <i class="ri-map-pin-line" style={{ color: "#f9a826" }}></i>{" "}
                    {house?.selected?.data?.street}
                  </span>

                  <span className="flex items-center gap-1 section__description">
                    <ion-icon
                      name="bed-outline"
                      style={{ color: "#f9a826" }}
                    ></ion-icon>
                    {`${house?.selected?.data?.bedRooms} Bebrooms`}
                  </span>
                  <span className="flex items-center gap-1 section__description">
                    <ion-icon
                      name="man-outline"
                      style={{ color: "#f9a826" }}
                    ></ion-icon>
                    {`${house?.selected?.data?.bathRooms} Bathrooms`}
                  </span>
                  <div className="flex flex-col pt-2 capitalize">
                    <span className="flex gap-x-3 section__description">
                      Location:{" "}
                      <ul>
                        <li>
                          Country: {house?.selected?.data?.address?.country}
                        </li>
                        <li>
                          Province: {house?.selected?.data?.address?.province}
                        </li>
                        <li>
                          District: {house?.selected?.data?.address?.district}
                        </li>
                        <li>
                          Sector: {house?.selected?.data?.address?.sector}
                        </li>
                        <li>Cell: {house?.selected?.data?.address?.cell}</li>
                        <li>
                          Village: {house?.selected?.data?.address?.village}
                        </li>
                      </ul>{" "}
                    </span>
                    <span className="flex items-center gap-1 section__description">
                      {`House Owner: ${house?.selected?.data?.houseOwner?.phone}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="grid md:grid-cols-2 gap-x-10">
            <div className="booking-info mt-5">
              <h5 className="mb-4 fw-bold ">Contact Us</h5>
              <BookingForm />
            </div>
          </div>
        </Container>
      </section>
    </Helmet>
  );
};

export default HouseDetails;
